import axios from 'axios';

const getAuthHeader = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  if (user && user.token) {
    return { Authorization: user.token };
  } else {
    return {};
  }
};

const register = (username, email, password) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/user/create`, {
      user: {
        username,
        email,
        password,
      },
    })
    .then((response) => {
      if (response.data.user.token) {
        localStorage.setItem('user', JSON.stringify(response.data.user));
      }

      return response.data;
    });
};

const login = (email, password) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/user/login`, {
      user: {
        email,
        password,
      },
    })
    .then((response) => {
      if (response.data.user.token) {
        localStorage.setItem('user', JSON.stringify(response.data.user));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem('user');
};

const AuthService = {
  getAuthHeader,
  register,
  login,
  logout,
};

export default AuthService;
