import React, { useState, useEffect } from 'react';

import TechTrees from '../../data/tech-trees';

const BuildStepInput = (props) => {
  const { formValues, setFormValues } = props;
  const [targetOptions, setTargetOptions] = useState(<></>);

  const applyFieldValue = (row, field, value) => {
    const newSteps = [...formValues.steps];
    newSteps[row][field] = value;
    setFormValues({
      ...formValues,
      steps: newSteps,
    });
  };

  const convertToFriendlyName = (name) => {
    const result = name.replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  };

  const addStep = () => {
    const newSteps = [...formValues.steps].concat([
      {
        timing: 0,
        resource: 'alloy',
        target: 'acropolis',
        quantity: 1,
        notes: '',
      },
    ]);

    setFormValues({
      ...formValues,
      steps: newSteps,
    });
  };

  const removeStep = () => {
    const newSteps = [...formValues.steps];
    newSteps.pop();

    setFormValues({
      ...formValues,
      steps: newSteps,
    });
  };

  const structureRequirementsMet = (target) => {
    const builtTargets = formValues.steps.map((step) => step.target);
    let result = true;

    target.requires.forEach((requirement) => {
      if (!builtTargets.includes(requirement)) {
        result = false;
      }
    });

    return result;
  };

  const immortalRequirementsMet = (target) => {
    return target.immortals.indexOf(props.immortal) !== -1;
  };

  useEffect(() => {
    const race = props.race;

    if (!TechTrees[race]) setTargetOptions(<></>);
    else {
      const options = Object.keys(TechTrees[race]).map((optionKey, index) => {
        if (
          (!TechTrees[race][optionKey].requires.length || structureRequirementsMet(TechTrees[race][optionKey])) &&
          (!TechTrees[race][optionKey].immortals || immortalRequirementsMet(TechTrees[race][optionKey]))
        ) {
          return (
            <option key={index} value={optionKey}>
              {TechTrees[race][optionKey].name}
            </option>
          );
        } else return <></>;
      });

      setTargetOptions(options);
    }
  }, [props.race, props.immortal, formValues.steps]);

  return (
    <>
      <table className='table table-sm table-striped text-body'>
        <thead className='font-elan text-uppercase'>
          <tr>
            <th>Timing</th> <th>Resource</th> <th>Unit/Building/Tech</th> <th>Qty</th> <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {formValues.steps.map((step, index) => (
            <tr key={index}>
              <td
                style={{
                  width: '6rem',
                }}
              >
                <input
                  style={{
                    width: '6rem',
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  type='number'
                  className='form-control'
                  value={step.timing}
                  onChange={(e) => applyFieldValue(index, 'timing', e.target.value)}
                />
              </td>
              <td>
                <select
                  type='number'
                  className='form-control form-select'
                  value={step.resource}
                  onChange={(e) => applyFieldValue(index, 'resource', e.target.value)}
                  placeholder='Timing resource'
                >
                  <option key={0} value='alloy'>
                    Alloy
                  </option>
                  <option key={1} value='ether'>
                    Ether
                  </option>
                  <option key={2} value='pyre'>
                    Pyre
                  </option>
                  <option key={3} value='supply'>
                    Supply
                  </option>
                  {formValues.steps.map((step, i) => {
                    if (step.target && i < index)
                      return (
                        <option className='text-capitalize' key={i + 4} value={step.target}>
                          % {convertToFriendlyName(step.target)}
                        </option>
                      );
                  })}
                </select>
              </td>
              <td>
                <select
                  type='number'
                  className='form-control form-select'
                  value={step.target}
                  onChange={(e) => applyFieldValue(index, 'target', e.target.value)}
                  placeholder='Unit/Building/Tech'
                >
                  {targetOptions}
                </select>
              </td>
              <td
                style={{
                  width: '6rem',
                }}
              >
                <input
                  style={{
                    width: '6rem',
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  type='number'
                  className='form-control'
                  value={step.quantity}
                  onChange={(e) => applyFieldValue(index, 'quantity', e.target.value)}
                />
              </td>
              <td>
                <input
                  type='text'
                  className='form-control'
                  value={step.notes}
                  onChange={(e) => applyFieldValue(index, 'notes', e.target.value)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ width: '100%' }} className='container-fluid d-flex justify-content-center p-0'>
        <button className='btn btn-primary btn-block text-nowrap me-3' onClick={removeStep}>
          <span>- REMOVE STEP</span>
        </button>
        <button className='btn btn-primary btn-block text-nowrap' onClick={addStep}>
          <span>+ ADD STEP</span>
        </button>
      </div>
    </>
  );
};

export default BuildStepInput;
