import React from 'react';

import FilterButton from './FilterButton';

import Icons from '../../images/icons';

const FilterBar = (props) => {
  return (
    <div className='card p-0'>
      <div className='card-header font-elan text-center'>FILTER BY</div>
      <ul className='list-group font-elan'>
        <FilterButton
          filterText='qrath'
          icon={Icons['qrath']}
          displayText={`Q'RATH`}
          currentFilter={props.currentFilter}
          applyFilter={props.applyFilter}
        />
        <FilterButton
          filterText='orzum'
          icon={Icons['orzum']}
          displayText={`ORZUM`}
          currentFilter={props.currentFilter}
          applyFilter={props.applyFilter}
        />
        <FilterButton
          filterText='ajari'
          icon={Icons['ajari']}
          displayText={`AJARI`}
          currentFilter={props.currentFilter}
          applyFilter={props.applyFilter}
        />
        <FilterButton
          filterText='aru'
          icon={Icons['aru']}
          displayText={`ARU`}
          currentFilter={props.currentFilter}
          applyFilter={props.applyFilter}
        />
        <FilterButton
          filterText='mala'
          icon={Icons['mala']}
          displayText={`MALA`}
          currentFilter={props.currentFilter}
          applyFilter={props.applyFilter}
        />
        <FilterButton
          filterText='xol'
          icon={Icons['xol']}
          displayText={`XOL`}
          currentFilter={props.currentFilter}
          applyFilter={props.applyFilter}
        />
      </ul>
      {props.currentFilter && (
        <button className='btn btn-primary btn-block' onClick={() => props.applyFilter('')}>
          <span>CLEAR</span>
        </button>
      )}
    </div>
  );
};

export default FilterBar;
