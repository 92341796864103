import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import AuthService from '../services/auth.service';
import UserService from '../services/user.service';

import BuildList from '../components/BuildList/BuildList';
import LoadingSpinner from '../components/LoadingSpinner';

const User = () => {
  const currentUser = UserService.getCurrentUser();

  const [favorites, setFavorites] = useState([]);
  const [userBuilds, setUserBuilds] = useState([]);

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({
    favorites: null,
    builds: null,
  });

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/builds`, { headers: AuthService.getAuthHeader() })
      .then((res) => {
        setFavorites(res.data.builds.filter((build) => build.favorited));
        setUserBuilds(res.data.builds.filter((build) => build.author.username == currentUser.username));
        setLoading(false);
      })
      .catch((err) => {
        setErrors({ ...errors, builds: err, favorites: err });
        setLoading(false);
      });
  }, []);

  return (
    <div className='container-fluid d-flex flex-column align-items-center justify-content-center'>
      <div className='col-md-10'>
        <div className='card container-fluid p-0'>
          <div className='card-header font-elan text-center'>USER</div>
          {!currentUser && (
            <div className='card-header alert text-center mb-0'>
              <Link to='/login'>
                <button className='btn btn-primary btn-block'>
                  <span>LOGIN</span>
                </button>
              </Link>
            </div>
          )}
          {currentUser && (
            <div className='col-auto p-3'>
              <header className='jumbotron'>
                <h3 className='font-elan text-uppercase text-center'>
                  <strong>{currentUser.username}</strong>
                </h3>
              </header>
              <p className='text-center'>
                <strong>Email:</strong> {currentUser.email}
              </p>
            </div>
          )}
        </div>
      </div>
      {currentUser && (
        <>
          <div className='col-md-10'>
            <BuildList
              header='MY BUILDS'
              loading={loading}
              showCreateButton={true}
              errors={errors}
              currentUser={currentUser}
              builds={userBuilds}
            />
          </div>
          <div className='col-md-10'>
            <BuildList header='FAVORITES' loading={loading} errors={errors} currentUser={currentUser} builds={favorites} />
          </div>{' '}
        </>
      )}
    </div>
  );
};

export default User;
