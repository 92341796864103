import React, { useState, useEffect } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './themes/immortal-theme.css';
import './App.css';
import './index.css';

import heroBg from './images/hero-bg.jpg';
import logoIcon from './images/logo-icon.svg';

import AuthService from './services/auth.service';
import UserService from './services/user.service';

import Landing from './pages/Landing';
import Login from './pages/Login';
import Register from './pages/Register';
import Home from './pages/Home';
import User from './pages/User';
import Create from './pages/Create';
import Profile from './pages/Profile';
import Build from './pages/Build';

import EventBus from './common/EventBus';

const App = () => {
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const user = UserService.getCurrentUser();

    if (user) {
      setCurrentUser(user);
    }

    EventBus.on('logout', () => {
      logOut();
    });

    return () => {
      EventBus.remove('logout');
    };
  }, []);

  const logOut = () => {
    AuthService.logout();
    setCurrentUser(undefined);
  };

  return (
    <div
      style={{
        backgroundImage: `url(${heroBg})`,
        backgroundSize: 'cover',
        minHeight: '100vh',
      }}
    >
      <nav className='navbar navbar-expand navbar-dark bg-dark' styles={{}}>
        <div className='container-fluid'>
          <Link to={'/home'} className='navbar-brand font-elan'>
            <img
              src={logoIcon}
              alt='immortal-logo'
              style={{
                width: '1.5rem',
                marginRight: '0.75rem',
                marginTop: '-0.25rem',
              }}
            />
            IMMORTALBUILDS
            {process.env.REACT_APP_API_URL.includes('localhost') && <span className='text-info ms-2'>LOCAL</span>}
            {process.env.REACT_APP_API_URL.includes('dev') && <span className='text-info ms-2'>DEV</span>}
          </Link>

          {currentUser ? (
            <div className='navbar-nav'>
              <li className='nav-item'>
                <Link to={'/user'} className='nav-link'>
                  <i className='bi bi-person-circle'></i>
                </Link>
              </li>
              <li className='nav-item'>
                <a href='/login' className='nav-link' onClick={logOut}>
                  <i className='bi bi-box-arrow-right'></i>
                </a>
              </li>
            </div>
          ) : (
            <div className='navbar-nav'>
              <li className='nav-item'>
                <Link to={'/login'} className='nav-link'>
                  Login
                </Link>
              </li>

              <li className='nav-item'>
                <Link to={'/register'} className='nav-link'>
                  Register
                </Link>
              </li>
            </div>
          )}
        </div>
      </nav>

      <div className='container mt-3'>
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/home' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/profile/:username' element={<Profile />} />
          <Route path='/create' element={<Create />} />
          <Route path='/user' element={<User />} />
          <Route path='/build/:slug' element={<Build />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
