import qrathIcon from './races/qrath.png';
import aruIcon from './races/aru.png';
import orzumIcon from './immortals/orzum.png';
import ajariIcon from './immortals/ajari.png';
import malaIcon from './immortals/mala.png';
import xolIcon from './immortals/xol.png';

const Icons = {
  qrath: qrathIcon,
  aru: aruIcon,
  orzum: orzumIcon,
  ajari: ajariIcon,
  mala: malaIcon,
  xol: xolIcon,
};

export default Icons;
