import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import UserService from '../services/user.service';
import AuthService from '../services/auth.service';

import FilterButton from '../components/FilterBar/FilterButton';
import Icons from '../images/icons';
import BuildStepInput from '../components/CreateForm/BuildStepInput';
import TechTrees from '../data/tech-trees';

const Create = () => {
  const currentUser = UserService.getCurrentUser();
  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    create: null,
  });

  const [buildSubmitted, setBuildSubmitted] = useState(false);

  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    race: '',
    immortal: '',
    description: '',
    body: '',
    steps: [
      {
        timing: 0,
        resource: 'alloy',
        target: 'acropolis',
        quantity: 1,
        notes: '',
      },
    ],
    tagList: [],
  });

  if (!currentUser) {
    navigate('/login');
    window.location.reload();
  }

  const applyRaceSelection = (race) => {
    setFormValues({
      ...formValues,
      race: race,
      immortal: '',
    });
  };

  const applyImmortalSelection = (immortal) => {
    let newFormValues = formValues;
    newFormValues.steps = newFormValues.steps.map((step) => {
      if (
        !TechTrees[formValues.race][step.target] ||
        (TechTrees[formValues.race][step.target].immortals && !TechTrees[formValues.race][step.target].immortals.includes(immortal))
      ) {
        return { ...step, target: Object.keys(TechTrees[formValues.race])[0] };
      } else {
        return step;
      }
    });

    console.log(newFormValues.steps);

    setFormValues({
      ...newFormValues,
      immortal: immortal,
    });
  };

  const applyTextFieldValue = (field, value) => {
    setFormValues({
      ...formValues,
      [field]: value,
    });
  };

  const applyTags = (tags) => {
    const newTags = tags.split(',').filter((tag) => tag.length > 0);

    setFormValues({
      ...formValues,
      tagList: newTags,
    });
  };

  const validateForm = () => {
    return (
      formValues.name.length > 0 &&
      formValues.description.length > 0 &&
      formValues.race.length > 0 &&
      formValues.immortal.length > 0 &&
      formValues.steps.length > 0
    );
  };

  const submitBuild = () => {
    setBuildSubmitted(true);
    setErrors({ create: null });
    axios
      .post(`${process.env.REACT_APP_API_URL}/build`, { build: { ...formValues } }, { headers: AuthService.getAuthHeader() })
      .then((res) => {
        navigate(`/build/${res.data.build.slug}`);
        window.location.reload();
      })
      .catch((err) => {
        setErrors({ ...errors, create: err });
        setBuildSubmitted(false);
      });
  };

  return (
    <div className='container-fluid d-flex justify-content-center'>
      <div className='col-md-10'>
        <div className='card container-fluid p-0'>
          <div className='card-header font-elan text-center'>NEW BUILD</div>
          <div className='col-auto p-3'>
            <div className='mb-3'>
              <label for='name-input' className='form-label'>
                Name
              </label>
              <input
                type='text'
                className='form-control'
                id='name-input'
                placeholder='No-Rush 20 Battlecruisers'
                onChange={(e) => applyTextFieldValue('name', e.target.value)}
              />
            </div>
            <div className='mb-3'>
              <label for='description-input' className='form-label'>
                Description
              </label>
              <input
                type='text'
                className='form-control'
                id='description-input'
                placeholder='A mouse-only, no harass build'
                onChange={(e) => applyTextFieldValue('description', e.target.value)}
              />
            </div>
            <div className='d-flex'>
              <div className='col-mb-3 flex-fill me-3'>
                <label for='race-selector' className='form-label'>
                  Race
                </label>
                <FilterButton
                  filterText='qrath'
                  icon={Icons['qrath']}
                  displayText={`Q'RATH`}
                  currentFilter={formValues.race}
                  applyFilter={applyRaceSelection}
                />
                <FilterButton
                  filterText='aru'
                  icon={Icons['aru']}
                  displayText={`ARU`}
                  currentFilter={formValues.race}
                  applyFilter={applyRaceSelection}
                />
              </div>
              <div className='col-mb-3 flex-fill'>
                <label for='immortal-selector' className='form-label'>
                  Immortal
                </label>
                <FilterButton
                  filterText='orzum'
                  icon={Icons['orzum']}
                  displayText={`ORZUM`}
                  currentFilter={formValues.immortal}
                  applyFilter={applyImmortalSelection}
                  disabled={!formValues.race || formValues.race !== 'qrath'}
                />
                <FilterButton
                  filterText='ajari'
                  icon={Icons['ajari']}
                  displayText={`AJARI`}
                  currentFilter={formValues.immortal}
                  applyFilter={applyImmortalSelection}
                  disabled={!formValues.race || formValues.race !== 'qrath'}
                />
                <FilterButton
                  filterText='mala'
                  icon={Icons['mala']}
                  displayText={`MALA`}
                  currentFilter={formValues.immortal}
                  applyFilter={applyImmortalSelection}
                  disabled={!formValues.race || formValues.race !== 'aru'}
                />
                <FilterButton
                  filterText='xol'
                  icon={Icons['xol']}
                  displayText={`XOL`}
                  currentFilter={formValues.immortal}
                  applyFilter={applyImmortalSelection}
                  disabled={!formValues.race || formValues.race !== 'aru'}
                />
              </div>
            </div>
            <div className='mb-3'>
              <label for='body-input' className='form-label'>
                Notes
              </label>
              <textarea
                className='form-control'
                id='body-input'
                placeholder='Build up to battlecruisers and please do not attack until 20:00'
                onChange={(e) => applyTextFieldValue('body', e.target.value)}
              />
            </div>
            <div className='mb-3'>
              <label for='body-input' className='form-label'>
                Tags
              </label>
              <input
                type='text'
                className='form-control'
                id='body-input'
                placeholder='no-rush, mouse-only, no-harass'
                onChange={(e) => applyTags(e.target.value.trim())}
              />
            </div>
            <div className='mb-3'>
              <label for='body-input' className='form-label'>
                Steps
              </label>
              <BuildStepInput race={formValues.race} immortal={formValues.immortal} formValues={formValues} setFormValues={setFormValues} />
            </div>
            <br />
          </div>
          <button
            className={`btn btn-primary btn-block ${validateForm() && !buildSubmitted ? '' : 'disabled'}`}
            onClick={() => submitBuild()}
          >
            <span>CREATE</span>
          </button>
          {errors.create && <div className='card-header alert alert-danger text-center mb-0'>{errors.create.message}</div>}
        </div>
      </div>
    </div>
  );
};

export default Create;
