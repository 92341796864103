const TechTrees = {
  qrath: {
    acropolis: {
      name: 'Acropolis',
      type: 'Town Hall',
      requires: [],
    },
    apostleOfBinding: {
      name: 'Apostle of Binding',
      type: 'Ether Extractor',
      requires: [],
    },
    legionHall: {
      name: 'Legion Hall',
      type: 'Production Structure',
      requires: [],
    },
    keeperOfTheHardenedFlame: {
      name: 'Keeper of the Hardened Flame',
      type: 'Upgrade Structure',
      requires: [],
    },
    soulFoundry: {
      name: 'Soul Foundry',
      type: 'Production Structure',
      requires: ['legionHall', 'apostleOfBinding'],
    },
    angelarium: {
      name: 'Angelarium',
      type: 'Production Structure',
      requires: ['soulFoundry', 'apostleOfBinding'],
    },
    bearerOfTheCrown: {
      name: 'Bearer of the Crown',
      type: 'Production Structure',
      requires: ['angelarium'],
    },
    throne: {
      name: 'Throne',
      type: 'Unit',
      requires: ['bearerOfTheCrown'],
    },
    eyeOfAros: {
      name: 'Eye of Aros',
      type: 'Production Structure',
      requires: ['angelarium'],
    },
    sharu: {
      name: 'Sharu',
      type: 'Unit',
      requires: ['eyeOfAros'],
    },
    sentinel: {
      name: 'Sentinel',
      type: 'Unit',
      requires: ['angelarium'],
    },
    warden: {
      name: 'Warden',
      type: 'Unit',
      immortals: ['ajari'],
      requires: ['angelarium'],
    },
    scepter: {
      name: 'Scepter',
      type: 'Unit',
      immortals: ['orzum'],
      requires: ['angelarium'],
    },
    houseOfTheFadingSaints: {
      name: 'House of the Fading Saints',
      type: 'Production Structure',
      requires: ['soulFoundry'],
    },
    hallower: {
      name: 'Hallower',
      type: 'Unit',
      immortals: ['orzum'],
      requires: ['houseOfTheFadingSaints'],
    },
    arkMother: {
      name: 'Ark Mother',
      type: 'Unit',
      immortals: ['ajari'],
      requires: ['houseOfTheFadingSaints'],
    },
    dervish: {
      name: 'Dervish',
      type: 'Unit',
      requires: ['soulFoundry'],
    },
    absolver: {
      name: 'Absolver',
      type: 'Unit',
      requires: ['soulFoundry'],
    },
    castigator: {
      name: 'Castigator',
      type: 'Unit',
      requires: ['soulFoundry'],
    },
    reliquary: {
      name: 'Reliquary',
      type: 'Production Structure',
      requires: ['legionHall', 'apostleOfBinding'],
    },
    monastaryOfIzur: {
      name: 'Monastary of Izur',
      type: 'Production Structure',
      requires: ['legionHall', 'apostleOfBinding'],
    },
    zephyr: {
      name: 'Zephyr',
      type: 'Unit',
      requires: ['monastaryOfIzur'],
    },
    magi: {
      name: 'Magi',
      type: 'Unit',
      immortals: ['orzum'],
      requires: ['reliquary'],
    },
    saoshin: {
      name: 'Saoshin',
      type: 'Unit',
      immortals: ['ajari'],
      requires: ['reliquary'],
    },
    fireSinger: {
      name: 'Fire Singer',
      type: 'Static Defense',
      requires: ['legionHall', 'keeperOfTheHardenedFlame'],
    },
    sipari: {
      name: 'Sipari',
      type: 'Unit',
      immortals: ['ajari'],
      requires: ['legionHall'],
    },
    zentari: {
      name: 'Zentari',
      type: 'Unit',
      immortals: ['orzum'],
      requires: ['legionHall'],
    },
    faithCastBlades: {
      name: 'Faith Cast Blades',
      type: 'Upgrade',
      immortals: ['orzum'],
      requires: ['reliquary'],
    },
    windStep: {
      name: 'Wind Step',
      type: 'Upgrade',
      requires: ['reliquary'],
    },
    zephyrRange: {
      name: 'Zephyr Range',
      type: 'Upgrade',
      requires: ['reliquary'],
    },
    siroccoScript: {
      name: 'Sirocco Script',
      type: 'Upgrade',
      requires: ['houseOfTheFadingSaints'],
    },
    radiantWard: {
      name: 'Radiant Ward',
      type: 'Upgrade',
      requires: ['houseOfTheFadingSaints'],
    },
    relicOfTheWrathfulGaze: {
      name: 'Relic of the Wrathful Gaze',
      type: 'Upgrade',
      requires: ['houseOfTheFadingSaints'],
    },
    bladesOfTheGodHead: {
      name: 'Blades of the God Head',
      type: 'Upgrade',
      requires: ['bearerOfTheCrown'],
    },
    iconOfKhastEem: {
      name: "Icon of Khast'Eem",
      type: 'Upgrade',
      requires: ['eyeOfAros'],
    },
  },
  aru: {
    groveHeart: {
      name: 'Grove Heart',
      type: 'Town Hall',
      requires: [],
    },
    godHeart: {
      name: 'God Heart',
      type: 'Upgrade Structure',
      requires: ['altarOfTheWorthy'],
    },
    etherMaw: {
      name: 'Ether Maw',
      type: 'Ether Extractor',
      requires: [],
    },
    altarOfTheWorthy: {
      name: 'Altar of the Worthy',
      type: 'Production Structure',
      requires: [],
    },
    murderHollow: {
      name: 'Murder Hollow',
      type: 'Upgrade Structure',
      requires: ['altarOfTheWorthy'],
    },
    rootCradle: {
      name: 'Root Cradle',
      type: 'Upgrade Structure',
      requires: ['groveHeart'],
    },
    aerovore: {
      name: 'Aerovore',
      type: 'Static Defense',
      requires: ['rootCradle'],
    },
    omnivore: {
      name: 'Omnivore',
      type: 'Static Defense',
      requires: ['rootCradle'],
    },
    neurocyte: {
      name: 'Neurocyte',
      type: 'Upgrade Structure',
      requires: ['altarOfTheWorthy'],
    },
    amberWomb: {
      name: 'Amber Womb',
      type: 'Production Structure',
      requires: ['godHeart'],
    },
    redVale: {
      name: 'Red Vale',
      type: 'Production Structure',
      requires: ['godHeart'],
    },
    boneCanopy: {
      name: 'Bone Canopy',
      type: 'Production Structure',
      requires: ['godHeart'],
    },
    deepNest: {
      name: 'Deep Nest',
      type: 'Production Structure',
      requires: ['boneCanopy'],
    },
    maskedHunter: {
      name: 'Masked Hunter',
      type: 'Unit',
      requires: ['altarOfTheWorthy'],
      immortals: ['mala'],
    },
    boneStalker: {
      name: 'Bone Stalker',
      type: 'Unit',
      requires: ['altarOfTheWorthy'],
      immortals: ['xol'],
    },
    xacal: {
      name: 'Xacal',
      type: 'Unit',
      requires: ['altarOfTheWorthy'],
    },
    incubator: {
      name: 'Incubator',
      type: 'Unit',
      requires: ['altarOfTheWorthy'],
      immortals: ['mala'],
    },
    underspine: {
      name: 'Underspine',
      type: 'Unit',
      requires: ['altarOfTheWorthy'],
      immortals: ['xol'],
    },
    ichor: {
      name: 'Ichor',
      type: 'Unit',
      requires: ['amberWomb'],
    },
    resinant: {
      name: 'Resinant',
      type: 'Unit',
      requires: ['amberWomb'],
    },
    wraithBow: {
      name: 'Wraith Bow',
      type: 'Unit',
      requires: ['amberWomb'],
    },
    bloodBound: {
      name: 'Bloodbound',
      type: 'Unit',
      requires: ['redVale'],
      immortals: ['mala'],
    },
    whitewoodReaper: {
      name: 'Whitewood Reaper',
      type: 'Unit',
      requires: ['redVale'],
      immortals: ['xol'],
    },
    redSeer: {
      name: 'Red Seer',
      type: 'Unit',
      requires: ['redVale'],
      immortals: ['xol'],
    },
    dreadSister: {
      name: 'Dread Sister',
      type: 'Unit',
      requires: ['redVale'],
      immortals: ['mala'],
    },
    thrum: {
      name: 'Thrum',
      type: 'Unit',
      requires: ['boneCanopy'],
    },
    aarox: {
      name: 'Aarox',
      type: 'Unit',
      requires: ['boneCanopy'],
    },
    behemoth: {
      name: 'Behemoth',
      type: 'Unit',
      requires: ['deepNest'],
    },
    xacalDamage: {
      name: 'Xacal Damage',
      type: 'Upgrade',
      requires: ['neurocyte'],
    },
    offering: {
      name: 'Offering',
      type: 'Upgrade',
      requires: ['neurocyte'],
    },
    denInstinct: {
      name: 'Den Instinct',
      type: 'Upgrade',
      requires: ['neurocyte'],
    },
    pursuitLigaments: {
      name: 'Pursuit Ligaments',
      type: 'Upgrade',
      requires: ['neurocyte'],
    },
    resinantDeploy: {
      name: 'Resinant Deploy',
      type: 'Upgrade',
      requires: ['neurocyte'],
    },
    bloodMothersFervor: {
      name: "Blood Mother's Fervor",
      type: 'Upgrade',
      requires: ['neurocyte'],
    },
    wraithBowRange: {
      name: 'Wraith Bow Range',
      type: 'Upgrade',
      requires: ['neurocyte'],
    },
    behemothCapacity: {
      name: 'Behemoth Capacity',
      type: 'Upgrade',
      requires: ['neurocyte, godHeart'],
    },
    birthingStorm: {
      name: 'Birthing Storm',
      type: 'Upgrade',
      requires: ['neurocyte, godHeart'],
    },
  },
};

export default TechTrees;
