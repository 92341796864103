import React, { useState } from 'react';
import axios from 'axios';

import AuthService from '../../services/auth.service';

import { useEffect } from 'react';

const FavoriteButton = (props) => {
  const { build, updateFavoriteStatus } = props;

  const [favorited, setFavorited] = useState(build.favorited);
  const [favoritesCount, setFavoritesCount] = useState(build.favoritesCount);

  useEffect(() => {
    setFavorited(build.favorited);
    setFavoritesCount(build.favoritesCount);
  }, [build]);

  const toggleFavorite = () => {
    if (favorited) {
      axios
        .delete(`${process.env.REACT_APP_API_URL}/build/${build.slug}/favorite`, { headers: AuthService.getAuthHeader() })
        .then((res) => {
          setFavorited(res.data.build.favorited);
          setFavoritesCount(res.data.build.favoritesCount);
          if (updateFavoriteStatus) {
            updateFavoriteStatus(build, res.data.build.favorited, res.data.build.favoritesCount);
          }
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/build/${build.slug}/favorite`, {}, { headers: AuthService.getAuthHeader() })
        .then((res) => {
          setFavorited(res.data.build.favorited);
          setFavoritesCount(res.data.build.favoritesCount);
          if (updateFavoriteStatus) {
            updateFavoriteStatus(build, res.data.build.favorited, res.data.build.favoritesCount);
          }
        });
    }
  };

  return (
    <span className='text-center fs-5 text-body' style={{ width: '5rem' }}>
      <button
        className='btn btn-primary btn-sm'
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggleFavorite(build.slug);
        }}
      >
        <i className={`bi ${favorited ? ' bi-star-fill' : ' bi-star'}`} /> {favoritesCount}
      </button>
    </span>
  );
};

export default FavoriteButton;
