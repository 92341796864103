import React from 'react';

const FilterButton = (props) => {
  return (
    <button
      className={`list-group-item 
              list-group-item-action 
              d-flex 
              flex-row 
              align-items-center 
              ${props.currentFilter == props.filterText ? ' active' : ''}
              ${props.disabled ? ' disabled text-muted' : 'text-body'}`}
      onClick={() => {
        if (!props.disabled) props.applyFilter(props.filterText);
      }}
    >
      <img src={props.icon} style={{ width: '3rem' }} alt={`${props.filterText}-icon`} />
      <span className='text-center text-nowrap fs-5 font-elan' style={{ width: '100%' }}>
        {props.displayText}
      </span>
    </button>
  );
};

export default FilterButton;
