import axios from 'axios';
import AuthService from './auth.service';

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem('user'));
};

const getUser = () => {
  return axios.get(process.env.REACT_APP_API_URL + 'user', {
    headers: AuthService.getAuthHeader(),
  });
};

const UserService = {
  getCurrentUser,
  getUser,
};

export default UserService;
