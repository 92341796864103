import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import AuthService from '../services/auth.service';
import UserService from '../services/user.service';

import BuildList from '../components/BuildList/BuildList';
import LoadingSpinner from '../components/LoadingSpinner';

const Profile = (props) => {
  const { username } = useParams();
  const currentUser = UserService.getCurrentUser();

  const [profileLoading, setProfileLoading] = useState(true);
  const [buildsLoading, setBuildsLoading] = useState(true);

  const [errors, setErrors] = useState({
    profile: null,
    builds: null,
  });
  const [profile, setProfile] = useState(null);
  const [builds, setBuilds] = useState([]);

  useEffect(() => {
    setProfileLoading(true);
    setBuildsLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/profile/${username}`)
      .then((res) => {
        const profile = res.data.profile;
        setProfile(res.data.profile);
        setProfileLoading(false);

        axios
          .get(`${process.env.REACT_APP_API_URL}/builds`, { headers: AuthService.getAuthHeader() })
          .then((res) => {
            setBuilds(res.data.builds.filter((build) => build.author.username == profile.username));
            setBuildsLoading(false);
          })
          .catch((err) => {
            setErrors({ ...errors, builds: err });
            setBuildsLoading(false);
          });
      })
      .catch((err) => {
        setErrors({ ...errors, profile: err });
        setProfileLoading(false);
      });
  }, [username]);

  return (
    <div className='container-fluid d-flex flex-column align-items-center justify-content-center'>
      <>
        <div className='col-md-10'>
          <div className='card container-fluid p-0'>
            <div className='card-header font-elan text-center'>PROFILE</div>
            {profileLoading && (
              <div className='card-header alert text-center mb-0'>
                <LoadingSpinner />
              </div>
            )}
            {errors.profile && <div className='card-header alert alert-danger text-center mb-0'>{errors.profile.message}</div>}
            {profile && (
              <div className='col-auto p-3'>
                <header className='jumbotron'>
                  <h3 className='font-elan text-uppercase text-center'>
                    <strong>{profile.username}</strong>
                  </h3>
                </header>
              </div>
            )}
          </div>
        </div>
        <div className='col-md-10'>
          <BuildList
            header={`${profile ? profile.username.toUpperCase() : 'USER'}'S BUILDS`}
            loading={buildsLoading}
            errors={errors}
            currentUser={currentUser}
            builds={builds}
          />
        </div>
      </>
    </div>
  );
};

export default Profile;
