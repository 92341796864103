import React from 'react';

const LoadingSpinner = () => (
  <div class='d-flex justify-content-center'>
    <div class='spinner-border text-primary' style={{ width: '1.5rem', height: '1.5rem' }} role='status'>
      <span class='visually-hidden'>Loading...</span>
    </div>
  </div>
);

export default LoadingSpinner;
