import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import UserService from '../services/user.service';

import immortalLogo from '../images/logo.svg';

const Landing = () => {
  const currentUser = UserService.getCurrentUser();
  const navigate = useNavigate();

  if (currentUser) {
    navigate('/home');
    window.location.reload();
  }

  return (
    <div className='container-fluid d-flex justify-content-center'>
      <header className='jumbotron'>
        <h3 style={{ color: 'white', textAlign: 'center' }}>
          <br />
          <Link to={'/login'}>
            <button className='btn btn-primary btn-block'>
              <span>LOGIN</span>
            </button>
          </Link>
          <span className='font-elan' style={{ margin: '1rem' }}>
            OR
          </span>
          <Link to={'/register'}>
            <button className='btn btn-primary btn-block'>
              <span>REGISTER</span>
            </button>
          </Link>
          <br />
          <br />
          <br />
          <span className='font-elan'>TO VIEW AND SHARE BUILD ORDERS FOR</span>
          <br />
          <br />
          <img src={immortalLogo} alt='immortal-logo' />
        </h3>
      </header>
    </div>
  );
};

export default Landing;
