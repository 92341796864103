import React from 'react';
import { Link } from 'react-router-dom';

import Icons from '../../images/icons';
import FavoriteButton from '../Favorites/FavoriteButton';

const BuildButton = (props) => {
  const { build, updateFavoriteStatus } = props;

  return (
    <Link to={`/build/${build.slug}`} style={{ textDecoration: 'none' }}>
      <button
        className={`list-group-item 
              list-group-item-action 
              d-flex 
              flex-row 
              align-items-center`}
      >
        <img src={Icons[build.immortal]} style={{ width: '3rem', marginRight: '2rem' }} alt={`${build.immortal}-icon`} />
        <div className='d-flex flex-row flex-grow-1 flex-fill align-items-center justify-content-left'>
          <div className='d-flex flex-column flex-grow-1 align-items-start justify-content-start'>
            <span className='text-nowrap fs-4 text-body font-elan'>{build.name}</span>
            <span className='text-nowrap fs-5 text-body' style={{ width: '100%' }}>
              {build.description}
            </span>
          </div>
          <div className='d-flex flex-column justify-content-end' style={{ marginRight: '3rem' }}>
            <span className='text-center text-nowrap fs-5 text-body' style={{ width: '100%' }}>
              {build.tagList.map((tag, index) => (
                <span key={`tag-${index}`} className='badge rounded-pill bg-primary text-white' style={{ marginRight: '0.5rem' }}>
                  {tag}
                </span>
              ))}
            </span>
            <span className='text-center fs-6 text-body' style={{ width: '100%' }}>
              Created by <Link to={`/profile/${build.author.username}`}>{build.author.username}</Link> on{' '}
              {new Date(parseInt(build.createdAt)).toLocaleDateString('en-US')}
            </span>
          </div>
          <div className='d-flex flex-column justify-content-end'>
            <FavoriteButton build={build} updateFavoriteStatus={updateFavoriteStatus} />
          </div>
        </div>
      </button>
    </Link>
  );
};

export default BuildButton;
