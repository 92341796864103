import React from 'react';

import Icons from '../../images/icons';

const SortBar = (props) => {
  return (
    <div className='card p-0'>
      <div className='card-header font-elan text-center'>SORT BY</div>
      <ul className='list-group font-elan'>
        <button
          className={`list-group-item 
              list-group-item-action 
              d-flex 
              flex-row 
              align-items-center 
              ${props.currentSort == 'favoritesCount' ? 'active' : ''}`}
          onClick={() => props.applySort('favoritesCount')}
        >
          <span className='text-center text-nowrap fs-6 text-body' style={{ width: '100%' }}>
            FAVORITES
          </span>
        </button>
        <button
          className={`list-group-item 
              list-group-item-action 
              d-flex 
              flex-row 
              align-items-center 
              ${props.currentSort == 'createdAt' ? 'active' : ''}`}
          onClick={() => props.applySort('createdAt')}
        >
          <span className='text-center text-nowrap fs-6 text-body' style={{ width: '100%' }}>
            NEWSET
          </span>
        </button>
        <button
          className={`list-group-item 
              list-group-item-action 
              d-flex 
              flex-row 
              align-items-center 
              ${props.currentSort == 'updatedAt' ? 'active' : ''}`}
          onClick={() => props.applySort('updatedAt')}
        >
          <span className='text-center text-nowrap fs-6 text-body' style={{ width: '100%' }}>
            UPDATED
          </span>
        </button>
      </ul>
      {props.currentSort && (
        <button className='btn btn-primary btn-block' onClick={() => props.applySort('')}>
          <span>CLEAR</span>
        </button>
      )}
    </div>
  );
};

export default SortBar;
