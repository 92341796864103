import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import BuildButton from './BuildButton';
import LoadingSpinner from '../LoadingSpinner';

const BuildList = (props) => {
  const { currentUser, errors, showCreateButton, loading } = props;

  return (
    <div className='card container-fluid p-0'>
      <div className='card-header font-elan text-center'>{props.header}</div>
      {currentUser && showCreateButton && (
        <Link to='/create' className='btn btn-primary btn-block'>
          <span>CREATE A NEW BUILD</span>
        </Link>
      )}
      {loading && (
        <div className='card-header alert text-center mb-0'>
          <LoadingSpinner />
        </div>
      )}
      {errors.builds && <div className='card-header alert alert-danger text-center mb-0'>{errors.builds.message}</div>}
      <ul className='list-group'>
        {props.builds.map((build, index) => {
          return <BuildButton key={`build-${index}`} build={build} updateFavoriteStatus={props.updateFavoriteStatus} />;
        })}
      </ul>
    </div>
  );
};

export default BuildList;
