import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

import TechTrees from '../data/tech-trees';
import AuthService from '../services/auth.service';
import UserService from '../services/user.service';

import Icons from '../images/icons';
import FilterButton from '../components/FilterBar/FilterButton';
import FavoriteButton from '../components/Favorites/FavoriteButton';
import LoadingSpinner from '../components/LoadingSpinner';

const Build = (props) => {
  const [buildLoading, setBuildLoading] = useState(true);
  const [commentsLoading, setCommentsLoading] = useState(true);

  const [errors, setErrors] = useState({
    build: null,
    comments: null,
  });

  const currentUser = UserService.getCurrentUser();
  const { slug } = useParams();

  const [build, setBuild] = useState(null);

  const [comments, setComments] = useState([]);
  const [commentBoxActive, setCommentBoxActive] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [commentSubmitted, setCommentSubmitted] = useState(false);

  const applyCommentText = (text) => {
    setNewComment(text);
  };

  const submitComment = () => {
    setCommentSubmitted(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/build/${slug}/comments`,
        { comment: { body: newComment } },
        { headers: AuthService.getAuthHeader() }
      )
      .then((res) => {
        setComments([res.data.comment, ...comments]);
        setCommentSubmitted(false);
        setNewComment('');
        setCommentBoxActive(false);
      })
      .catch((err) => {
        setErrors({ ...errors, comments: err });
        setCommentSubmitted(false);
      });
  };

  const updateFavoriteStatus = (build, favorited, favoritesCount) => {
    const updatedBuild = { ...build };
    updatedBuild.favorited = favorited;
    updatedBuild.favoritesCount = favoritesCount;
    setBuild(updatedBuild);
  };

  useEffect(() => {
    setBuildLoading(true);
    setCommentsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/build/${slug}`, { headers: AuthService.getAuthHeader() })
      .then((res) => {
        setBuild(res.data.build);
        setBuildLoading(false);

        axios
          .get(`${process.env.REACT_APP_API_URL}/build/${slug}/comments`)
          .then((res) => {
            let comments = res.data.comments;
            comments.sort((a, b) => b.createdAt - a.createdAt);
            setComments(comments);
            setCommentsLoading(false);
          })
          .catch((err) => {
            setErrors({ ...errors, comments: err });
            setCommentsLoading(false);
          });
      })
      .catch((err) => {
        setErrors({ ...errors, build: err });
        setBuildLoading(false);
      });
  }, [slug]);

  const convertToFriendlyResourceName = (name) => {
    const result = name.replace(/([A-Z])/g, ' $1');
    let finalResult = result.charAt(0).toUpperCase() + result.slice(1);

    if (finalResult != 'Alloy' && finalResult != 'Ether' && finalResult != 'Pyre' && finalResult != 'Supply') {
      finalResult = '% ' + finalResult;
    }

    return finalResult;
  };

  return (
    <div className='container-fluid d-flex justify-content-center'>
      <div className='col-md-10'>
        <div className='card container-fluid p-0'>
          <div className='card-header font-elan text-center'>BUILD</div>
          {buildLoading && (
            <div className='card-header alert text-center mb-0'>
              <LoadingSpinner />
            </div>
          )}
          {errors.build && <div className='card-header alert alert-danger text-center mb-0'>{errors.build.message}</div>}
          {build && (
            <div className='col-auto p-3'>
              <div className='mb-3'>
                <div className='d-flex align-items-center'>
                  <h3 className='font-elan text-uppercase flex-fill'>{build.name}</h3>
                  {currentUser && <FavoriteButton build={build} updateFavoriteStatus={updateFavoriteStatus} />}
                </div>
                <span className='text-center fs-6 text-body' style={{ width: '100%' }}>
                  Created by <Link to={`/profile/${build.author.username}`}>{build.author.username}</Link> on{' '}
                  {new Date(parseInt(build.createdAt)).toLocaleDateString('en-US')}
                </span>
              </div>
              <div className='mb-3'>{build.description}</div>
              <div className='d-flex'>
                <div className='col-mb-3 flex-fill me-3'>
                  {build.race == 'qrath' && (
                    <FilterButton filterText='qrath' icon={Icons['qrath']} displayText={`Q'RATH`} disabled={true} />
                  )}
                  {build.race == 'aru' && <FilterButton filterText='aru' icon={Icons['aru']} displayText={`ARU`} disabled={true} />}
                </div>
                <div className='col-mb-3 flex-fill'>
                  {build.immortal == 'orzum' && (
                    <FilterButton filterText='orzum' icon={Icons['orzum']} displayText={`ORZUM`} disabled={true} />
                  )}
                  {build.immortal == 'ajari' && (
                    <FilterButton filterText='ajari' icon={Icons['ajari']} displayText={`AJARI`} disabled={true} />
                  )}
                  {build.immortal == 'mala' && <FilterButton filterText='mala' icon={Icons['mala']} displayText={`MALA`} disabled={true} />}
                  {build.immortal == 'xol' && <FilterButton filterText='xol' icon={Icons['xol']} displayText={`XOL`} disabled={true} />}
                </div>
              </div>
              <div className='mb-3'>
                {build.tagList.map((tag, index) => (
                  <span key={`tag-${index}`} className='badge rounded-pill bg-primary text-white' style={{ marginRight: '0.5rem' }}>
                    {tag}
                  </span>
                ))}
              </div>
              {build.body.length > 0 && (
                <div className='mb-3'>
                  <h5 className='font-elan text-uppercase'>Build Notes</h5>
                  {build.body}
                </div>
              )}
              <div className='mb-3'>
                <table className='table table-sm table-striped text-body'>
                  <thead className='font-elan text-uppercase'>
                    <tr>
                      <th>Timing</th> <th>Resource</th> <th>Unit/Building/Tech</th> <th>Qty</th> <th>Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {build.steps.map((step, index) => (
                      <tr key={index}>
                        <td>{step.timing}</td>
                        <td>{convertToFriendlyResourceName(step.resource)}</td>
                        <td>{TechTrees[build.race][step.target]?.name}</td>
                        <td>{step.quantity}</td>
                        <td>{step.notes}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <br />
              <div className='mb-3'>
                <div className='d-flex align-items-center'>
                  <h5 className='font-elan text-uppercase flex-fill'>Comments</h5>
                  {currentUser && (
                    <button className='btn btn-primary mb-3' onClick={() => setCommentBoxActive(!commentBoxActive)}>
                      ADD COMMENT
                    </button>
                  )}
                </div>
                {commentsLoading && (
                  <div className='card-header alert text-center mb-0'>
                    <LoadingSpinner />
                  </div>
                )}
                {errors.comments && <div className='card-header alert alert-danger text-center mb-0'>{errors.comments.message}</div>}
                {commentBoxActive && (
                  <div className='mb-3'>
                    <textarea
                      className='form-control'
                      id='comment-input'
                      placeholder='Leave a comment...'
                      onChange={(e) => applyCommentText(e.target.value)}
                    />
                    <div className='d-flex align-items-center'>
                      <h5 className='font-elan text-uppercase flex-fill'></h5>
                      <button className={`btn btn-primary mt-3 ${commentSubmitted ? 'disabled' : ''}`} onClick={() => submitComment()}>
                        SUBMIT
                      </button>
                    </div>
                  </div>
                )}
                {comments.map((comment, index) => (
                  <div className='mb-3' key={`comment-${index}`}>
                    <span className='text-center fs-6 text-body' style={{ width: '100%' }}>
                      <Link to={`/profile/${comment.author.username}`}>{comment.author.username}</Link> on{' '}
                      {new Date(parseInt(comment.createdAt)).toLocaleDateString('en-US')}
                    </span>
                    <br />
                    <div className='ms-3'>{comment.body}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Build;
