import React, { useState, useEffect } from 'react';
import axios from 'axios';

import AuthService from '../services/auth.service';
import UserService from '../services/user.service';

import LoadingSpinner from '../components/LoadingSpinner';

import FilterBar from '../components/FilterBar/FilterBar';
import SortBar from '../components/SortBar/SortBar';
import BuildList from '../components/BuildList/BuildList';

const Profile = () => {
  const currentUser = UserService.getCurrentUser();
  const [currentFilter, setCurrentFilter] = useState('');
  const [currentSort, setCurrentSort] = useState('');

  const [loading, setLoading] = useState(true);
  const [builds, setBuilds] = useState([]);
  const [errors, setErrors] = useState({ builds: null });

  const [buildsFiltered, setBuildsFiltered] = useState([]);

  useEffect(() => {
    if (currentFilter) {
      let newBuilds = [...builds];

      if (currentSort) {
        newBuilds = newBuilds.sort((a, b) => {
          return a[currentSort] < b[currentSort];
        });
      }

      setBuildsFiltered(newBuilds.filter((build) => build.race == currentFilter || build.immortal == currentFilter));
    } else {
      let newBuilds = [...builds];

      if (currentSort) {
        newBuilds = newBuilds.sort((a, b) => {
          return a[currentSort] < b[currentSort];
        });
      }

      setBuildsFiltered(newBuilds);
    }
  }, [currentFilter, currentSort, builds]);

  const updateFavoriteStatus = (build, favorited, favoritesCount) => {
    const newBuilds = [...builds];
    const buildIndex = newBuilds.findIndex((b) => b.slug == build.slug);

    newBuilds[buildIndex].favorited = favorited;
    newBuilds[buildIndex].favoritesCount = favoritesCount;

    setBuilds(newBuilds);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/builds`, { headers: AuthService.getAuthHeader() })
      .then((res) => {
        setBuilds(res.data.builds);
        setLoading(false);
      })
      .catch((err) => {
        setErrors({ builds: err });
        setLoading(false);
      });
  }, []);

  const applyFilter = (filter) => {
    setCurrentFilter(filter);
  };

  const applySort = (sort) => {
    setCurrentSort(sort);
  };

  return (
    <div className='row'>
      <div className='col-md-3'>
        <FilterBar currentFilter={currentFilter} applyFilter={applyFilter} />
        <SortBar currentSort={currentSort} applySort={applySort} />
      </div>
      <div className='col-md-9'>
        <BuildList
          header='BUILDS'
          loading={loading}
          showCreateButton={true}
          currentUser={currentUser}
          builds={buildsFiltered}
          errors={errors}
          updateFavoriteStatus={updateFavoriteStatus}
        />
      </div>
    </div>
  );
};

export default Profile;
